import React from "react";
import { motion } from "framer-motion";
import {
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { LinearProgress } from "@mui/material";
import t from "./transitions";
import Spinner from "./Spinner";
import Fields from "./Fields";

class Tab extends React.Component {
  constructor(props) {
    super();
    this.status = props.status;
  }

  getIcon = (status) => {
    switch (status) {
      case "inProgress":
        if (this.props.complete)
          return <MDBIcon className="text-danger me-2" fas icon="times" />;
        return <Spinner size="sm" color="primary" className="me-2" />;
      case "complete":
        return (
          <MDBIcon className="text-success me-2" fas icon="check-circle" />
        );
      case "failure":
        return <MDBIcon className="text-danger me-2" fas icon="times" />;
      default:
        return (
          <MDBIcon className="text-primary me-2" fas icon="question-circle" />
        );
    }
  };

  render() {
    let {
      setStatus,
      details,
      setDetails,
      complete,
      setComplete,
      socket,
      setLogs,
      toRun,
      inputValues,
      runID,
      screenshots,
      setScreenshots,
      setScreenshotSelected,
    } = this.props;

    switch (this.status) {
      case "ready":
        return (
          <motion.div
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            transition={t.transition}
          >
            <Fields {...this.props} />
            <MDBBtn
              color="success"
              onClick={() => {
                if (!toRun.length)
                  return alert("You must select at least one test to run");
                setStatus("starting");
                setLogs([]);
                setDetails([]);
                setScreenshots([]);
                socket.emit("start-tests", toRun, inputValues);
              }}
              className="d-block mx-auto mt-3 text-light"
              disabled={!inputValues.branchID || !inputValues.name}
            >
              <MDBIcon fas icon="paper-plane" className="me-2" />
              Start Tests
            </MDBBtn>
          </motion.div>
        );
      case "starting":
        return (
          <motion.section
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            transition={t.transition}
          >
            <h5 className="text-center mb-3">Initializing</h5>
            <LinearProgress />
          </motion.section>
        );
      case "running":
        return (
          <motion.div
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            transition={t.transition}
          >
            <h5 className="text-center m-0">
              {complete ? (
                details.find(
                  (detail) =>
                    detail.status === "inProgress" ||
                    detail.results?.find((result) => !result.success)
                ) ? (
                  <>
                    <MDBIcon fas icon="times" className="text-danger me-2" />
                    Tests Failed
                  </>
                ) : (
                  <>
                    <MDBIcon
                      fas
                      icon="check-circle"
                      className="text-success me-2"
                    />
                    Tests Succeeded
                  </>
                )
              ) : (
                "Running Tests"
              )}
            </h5>

            <hr />
            {runID ? (
              <p>
                <span className="fw-bold">Run ID: </span>
                {runID}
              </p>
            ) : (
              <></>
            )}
            {details.map((detail) => (
              <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                transition={t.transition}
              >
                <div className="d-flex align-items-center">
                  {this.getIcon(detail.status)}
                  {detail.text}
                </div>

                {detail.results?.map((result) => (
                  <motion.div
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    transition={t.transition}
                    key={result.text}
                    className="d-flex align-items-center ps-4"
                  >
                    <MDBIcon
                      size="sm"
                      className={
                        "me-2 text-" + (result.success ? "success" : "danger")
                      }
                      fas
                      icon={result.success ? "check-circle" : "times"}
                    />
                    {result.text}
                  </motion.div>
                ))}
              </motion.div>
            ))}
            {complete ? (
              <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                transition={t.transition}
              >
                <Fields {...this.props} />
                <MDBBtn
                  color="success"
                  onClick={() => {
                    if (!toRun.length)
                      return alert("You must select at least one test to run");
                    setComplete(false);
                    setDetails([]);
                    setLogs([]);
                    setScreenshots([]);
                    socket.emit("start-tests", toRun, inputValues);
                  }}
                  className="d-block mx-auto mt-4 text-light"
                >
                  <MDBIcon fas icon="undo-alt" className="me-2" />
                  Re-run tests
                </MDBBtn>
              </motion.div>
            ) : (
              <></>
            )}
            {screenshots.length && runID ? (
              <>
                <h5 className="text-center mb-0 mt-4">Screenshots</h5>
                <hr />
                <MDBContainer fluid>
                  <MDBRow>
                    {screenshots.map((screenshot) => (
                      <MDBCol sm={12} md={6} lg={4}>
                        <img
                          className="d-block max-w-100 max-h-100 cursor-pointer"
                          alt={screenshot.replace(".png", "")}
                          src={`/${runID}/${screenshot}`}
                          onClick={() =>
                            setScreenshotSelected(`/${runID}/${screenshot}`)
                          }
                        />
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBContainer>
              </>
            ) : (
              <></>
            )}
          </motion.div>
        );
      default:
        return <></>;
    }
  }
}

export default Tab;
