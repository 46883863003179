import React, { useEffect, useState } from "react";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { io } from "socket.io-client";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { AnimatePresence, motion } from "framer-motion";
import t from "./transitions";
import Tab from "./Tab";
import { MDBContainer } from "mdb-react-ui-kit";
import ScreenshotModal from "./ScreenshotModal";

const defaultBranch = (
  new URL(document.location).searchParams.get("build") || "test"
).split("/")[0];

const socket = io("/");

function App() {
  const [inputValues, setInputValues] = useState({
    branchID: defaultBranch,
    name: "Fish",
  });
  const [screenshots, setScreenshots] = useState([]);
  const [runID, setRunID] = useState("");
  const [status, setStatus] = useState("ready");
  const [details, setDetails] = useState([]);
  const [complete, setComplete] = useState(false);
  const [logView, setLogView] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [logs, setLogs] = useState([]);
  const [toRun, setToRun] = useState([
    "saveFields",
    "uploadFiles",
    "configureLoan",
    "persistChanges",
    "loanProcessing",
    // "loanDocuments",
    "documentPermissions",
  ]);
  const [screenshotSelected, setScreenshotSelected] = useState("");

  useEffect(() => {
    socket.on("status", (status) => {
      console.log("status", status);
      setStatus(status);
    });
    socket.on("details", (details) => {
      console.log("details", details);
      setDetails(details);
    });
    socket.on("complete", () => {
      console.log("complete");
      setComplete(true);
    });
    socket.on("log", (log) => {
      console.log("log", log);
      setLogs((curr) => {
        return [...curr, log];
      });
    });
    socket.on("runID", (runID) => setRunID(runID));
    socket.on("screenshots", (screenshots) => setScreenshots(screenshots));
  }, []);

  const changeHandler = (e) => {
    try {
      setInputValues((curr) => ({
        ...curr,
        [e.target.name]: e.target.value,
      }));

      document
        .getElementById(e.target.name)
        .setCustomValidity(e.target.value ? "" : "Required");
      if (!e.target.value) {
        document.getElementById("form").classList.add("was-validated");
      }
    } catch (err) {
      console.log("changeHandler error", err);
    }
  };

  const props = {
    status,
    setStatus,
    details,
    setDetails,
    complete,
    setComplete,
    logView,
    setLogView,
    activeTab,
    setActiveTab,
    socket,
    logs,
    setLogs,
    toRun,
    setToRun,
    inputValues,
    changeHandler,
    runID,
    screenshots,
    setScreenshots,
    setScreenshotSelected,
  };

  return (
    <MDBContainer className="py-4" fluid>
      <ScreenshotModal
        modalShown={screenshotSelected}
        toggleShowModal={() => setScreenshotSelected("")}
        screenshot={screenshotSelected}
      />
      <div className="row">
        <div className="col-6">
          <StaticRouter location={status}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={status}>
                <Route exact path=":id">
                  <Tab {...props} />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        </div>
        <div className="col-6">
          <h5 className="text-center m-0">Logs</h5>
          <hr />
          <React.Fragment>
            {logs
              .filter(
                (log) => !log.includes("Jest has detected the following ")
              )
              .map((log) => (
                <motion.p
                  key={log}
                  transition={t.transition}
                  initial={t.fade_out}
                  animate={t.normalize}
                  exit={t.fade_out_scale_1}
                >
                  {log.replace("console.log", "").replace("at Object.log ", "")}
                </motion.p>
              ))}
          </React.Fragment>
        </div>
      </div>
    </MDBContainer>
  );
}

export default App;
