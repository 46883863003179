import React from "react";
import {
  MDBContainer,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBSelect,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

const Fields = ({ changeHandler, inputValues, toRun, setToRun }) => {
  return (
    <>
      <MDBSelect
        data={[
          {
            text: "Loan App Fields Save",
            value: "saveFields",
            defaultSelected: toRun.includes("saveFields"),
          },
          {
            text: "File Uploads",
            value: "uploadFiles",
            defaultSelected: toRun.includes("uploadFiles"),
          },
          {
            text: "Configuring/Submitting Loan App",
            value: "configureLoan",
            defaultSelected: toRun.includes("configureLoan"),
          },
          {
            text: "Loan App Fields Persist",
            value: "persistChanges",
            defaultSelected: toRun.includes("persistChanges"),
          },
          {
            text: "Loan Processing/Investor Changes",
            value: "loanProcessing",
            defaultSelected: toRun.includes("loanProcessing"),
          },
          // {
          //   text: "Loan Documents",
          //   value: "loanDocuments",
          //   defaultSelected: toRun.includes("loanDocuments"),
          // },
          {
            text: "Document Permissions",
            value: "documentPermissions",
            defaultSelected: toRun.includes("documentPermissions"),
          },
        ]}
        multiple
        label="Tests to Run"
        onChange={(e) => setToRun(e.map((item) => item.value))}
        id="select-to-run"
        className="mt-4"
        visibleOptions={8}
        displayedLabels={1}
        optionsSelectedLabel="Tests Selected"
      />
      <MDBValidation id="form">
        <MDBContainer fluid className="pb-4">
          <p className="text-center mb-0 mt-4">
            https://https://ifish.tech/version-{inputValues.branchID}
          </p>
          <MDBRow>
            <MDBCol className="mt-4" lg={6}>
              <MDBValidationItem
                feedback={!inputValues.name ? "Please enter a name" : ""}
                invalid={true}
              >
                <MDBInput
                  name="name"
                  onChange={changeHandler}
                  id="name"
                  label="Your Name"
                  value={inputValues.name}
                />
              </MDBValidationItem>
            </MDBCol>
            <MDBCol className="mt-4" lg={6}>
              <MDBValidationItem
                feedback={!inputValues.branchID ? "Please enter a branch" : ""}
                invalid={true}
              >
                <MDBInput
                  name="branchID"
                  onChange={changeHandler}
                  id="branchID"
                  label="Branch ID"
                  value={inputValues.branchID}
                />
              </MDBValidationItem>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBValidation>
    </>
  );
};

export default Fields;
